// Gatsby supports TypeScript natively!
// @ts-ignore
import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Books = (props: PageProps) => (
  <Layout>
    <SEO title="Books" />
    <p>Books I buy and fail to read</p>
  </Layout>
)

export default Books
